import React, { useState, useEffect, useMemo } from 'react';
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet';
import * as L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { api, Resource } from '../services/api';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.markercluster';

// Create custom marker icons
const defaultIcon = new L.Icon({
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const selectedIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

// This component handles map view changes
function MapController({ selectedResource }: { selectedResource?: Resource | null }) {
  const map = useMap();

  useEffect(() => {
    if (selectedResource && selectedResource.location) {
      const [lat, lng] = selectedResource.location;
      map.flyTo([lat, lng], 16, {
        duration: 1.5,
        easeLinearity: 0.25
      });
    }
  }, [map, selectedResource]);

  return null;
}

// Markers container component
function ResourceMarkers({ 
  resources, 
  selectedResource, 
  onResourceSelect 
}: { 
  resources: Resource[];
  selectedResource?: Resource | null;
  onResourceSelect: (resource: Resource) => void;
}) {
  const map = useMap();

  useEffect(() => {
    // Create a marker cluster group
    const markers = L.markerClusterGroup({
      maxClusterRadius: 40,
      spiderfyOnMaxZoom: true,
      showCoverageOnHover: false,
      iconCreateFunction: (cluster) => {
        return L.divIcon({
          html: `<div class="cluster-icon">${cluster.getChildCount()}</div>`,
          className: 'custom-marker-cluster',
          iconSize: L.point(40, 40)
        });
      }
    });

    // Add markers to the cluster group
    resources.forEach(resource => {
      const marker = L.marker(resource.location, {
        icon: selectedResource?.id === resource.id ? selectedIcon : defaultIcon
      });
      
      marker.on('click', () => onResourceSelect(resource));
      markers.addLayer(marker);
    });

    // Add the cluster group to the map
    map.addLayer(markers);

    // Cleanup
    return () => {
      map.removeLayer(markers);
    };
  }, [map, resources, selectedResource, onResourceSelect]);

  return null;
}

interface MapProps {
  onResourceSelect?: (resource: Resource | null) => void;
  selectedResource?: Resource | null;
}

const Map: React.FC<MapProps> = ({ onResourceSelect, selectedResource }) => {
  const [resources, setResources] = useState<Resource[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchResources = async () => {
      try {
        const data = await api.getResources();
        
        const validResources = data.filter(resource => {
          const [lat, lng] = resource.location;
          const isValid = 
            typeof lat === 'number' && 
            typeof lng === 'number' &&
            !isNaN(lat) && 
            !isNaN(lng) &&
            lat >= -90 && lat <= 90 &&
            lng >= -180 && lng <= 180;
          
          if (!isValid) {
            console.warn('Invalid resource location:', resource);
          }
          return isValid;
        });

        setResources(validResources);
      } catch (err) {
        console.error('Error fetching resources for map:', err);
        setError('Failed to load resources');
      }
    };

    fetchResources();
  }, []);

  const handleResourceSelect = (resource: Resource) => {
    if (onResourceSelect) {
      onResourceSelect(resource);
    }
  };

  const mapContainer = useMemo(() => (
    <MapContainer
      center={[34.0522, -118.2437]}
      zoom={12}
      style={{ height: '100%', width: '100%' }}
    >
      <MapController selectedResource={selectedResource} />
      <TileLayer
        attribution='© Stadia Maps © Stamen Design © OpenMapTiles © OpenStreetMap contributors'
        url="https://tiles.stadiamaps.com/tiles/stamen_toner/{z}/{x}/{y}{r}.png"
      />
      <ResourceMarkers 
        resources={resources}
        selectedResource={selectedResource}
        onResourceSelect={handleResourceSelect}
      />
    </MapContainer>
  ), [resources, selectedResource, onResourceSelect]);

  if (error) {
    console.error('Error loading resources:', error);
  }

  return mapContainer;
};

export default Map; 