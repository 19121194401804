const API_BASE_URL = 'https://col-production.up.railway.app';

export interface Resource {
  id: string;
  name: string;
  location: [number, number];
  type: string;
  address?: string;
  contact?: string;
  [key: string]: any; // Allow any additional fields
}

interface TableResponse {
  tables: string[];
}

interface TableInfo {
  name: string;
  columns: Array<{
    name: string;
    type: string;
    nullable: string;
  }>;
  total_records: number;
}

interface QueryResponse {
  total: number;
  offset: number;
  limit: number;
  records: any[];
}

interface LocationData {
  state?: string;
  latitude?: number;
  longitude?: number;
  geocoded_address?: string;
}

export const api = {
  async getTables(): Promise<string[]> {
    try {
      console.log('Fetching tables...');
      const response = await fetch(`${API_BASE_URL}/tables`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data: TableResponse = await response.json();
      console.log('Available tables:', data);
      return data.tables;
    } catch (error) {
      console.error('Error fetching tables:', error);
      throw error;
    }
  },

  async getTableInfo(tableName: string): Promise<TableInfo> {
    try {
      console.log(`Fetching info for table: ${tableName}`);
      const response = await fetch(`${API_BASE_URL}/tables/${tableName}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data: TableInfo = await response.json();
      console.log(`Table info for ${tableName}:`, data);
      return data;
    } catch (error) {
      console.error(`Error fetching table info for ${tableName}:`, error);
      throw error;
    }
  },

  async queryTable(tableName: string): Promise<any[]> {
    try {
      console.log(`Querying table: ${tableName}`);
      let allRecords: any[] = [];
      let currentOffset = 0;
      const pageSize = 100;

      while (true) {
        const response = await fetch(
          `${API_BASE_URL}/query/${tableName}?limit=${pageSize}&offset=${currentOffset}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data: QueryResponse = await response.json();
        console.log(`Query results for ${tableName}:`, data);

        allRecords = [...allRecords, ...data.records];

        if (data.records.length < pageSize) {
          break;
        }
        currentOffset += pageSize;
      }

      return allRecords;
    } catch (error) {
      console.error(`Error querying table ${tableName}:`, error);
      throw error;
    }
  },

  async getResources(): Promise<Resource[]> {
    try {
      // First, get list of available tables
      const tables = await this.getTables();
      console.log('Available tables:', tables);

      // Look for all tables that might contain resources
      const resourceTables = tables.filter(table => 
        table.toLowerCase().includes('resource') || 
        table.toLowerCase().includes('location') ||
        table.toLowerCase().includes('place')
      );

      console.log("resourceTables", resourceTables);

      if (!resourceTables.length) {
        console.log('No resource tables found, using mock data');
        return [
          {
            id: '1',
            name: 'Food Bank LA',
            location: [34.0522, -118.2437],
            type: 'food',
            address: '123 Main St, Los Angeles, CA',
            contact: '(213) 555-0123'
          },
          {
            id: '2',
            name: 'Medical Supplies Center',
            location: [34.0622, -118.2537],
            type: 'medical',
            address: '456 Spring St, Los Angeles, CA',
            contact: '(213) 555-0456'
          },
          {
            id: '3',
            name: 'Emergency Shelter',
            location: [34.0422, -118.2337],
            type: 'shelter',
            address: '789 Hope St, Los Angeles, CA',
            contact: '(213) 555-0789'
          }
        ];
      }

      // Get all resources from all matching tables
      const resourcePromises = resourceTables.map(async (tableName) => {
        try {
          // Get table info to understand its structure
          const tableInfo = await this.getTableInfo(tableName);
          console.log(`Resource table structure for ${tableName}:`, tableInfo);

          // Query the resources
          const resources = await this.queryTable(tableName);
          console.log(`Raw resource data from ${tableName}:`, resources);

          if (Array.isArray(resources)) {
            return resources;
          } else {
            console.warn(`Skipping non-array results from ${tableName}`);
            return [];
          }
        } catch (error) {
          console.error(`Error processing table ${tableName}:`, error);
          return []; // Return empty array for failed tables
        }
      });

      const resourceArrays = await Promise.all(resourcePromises);
      const allResources = resourceArrays.flat();

      if (!allResources.length) {
        throw new Error('No resources found in any tables');
      }

      // Transform the data to match our Resource interface while preserving all fields
      return allResources.map((resource: any) => {
        // Handle location data that might be a string or an object
        let latitude: number | undefined;
        let longitude: number | undefined;
        let address: string | undefined;

        if (typeof resource.location === 'object' && resource.location !== null) {
          const location = resource.location as LocationData;
          latitude = location.latitude;
          longitude = location.longitude;
          address = location.geocoded_address;
        } else if (typeof resource.location === 'string') {
          try {
            const location = JSON.parse(resource.location) as LocationData;
            latitude = location.latitude;
            longitude = location.longitude;
            address = location.geocoded_address;
          } catch (e) {
            console.warn('Failed to parse location string:', resource.location);
          }
        }

        // Use direct lat/long fields if location object parsing failed
        latitude = latitude || parseFloat(resource.latitude || resource.lat) || 34.0522;
        longitude = longitude || parseFloat(resource.longitude || resource.lng || resource.long) || -118.2437;
        address = address || resource.address || resource.geocoded_address || '';

        // Create the base resource with required fields
        const transformedResource: Resource = {
          id: (resource.id || resource.resource_id || '').toString(),
          name: resource.name || resource.resource_name || 'Unknown Resource',
          location: [latitude, longitude] as [number, number],
          type: resource.type || resource.resource_type || 'other',
          address: address,
          contact: resource.contact || resource.phone || resource.email
        };

        // Add all additional fields from the original resource
        for (const [key, value] of Object.entries(resource)) {
          if (!(key in transformedResource)) {
            transformedResource[key] = value;
          }
        }

        return transformedResource;
      });
    } catch (error) {
      console.error('Error in getResources:', error);
      // Return mock data as fallback
      return [
        {
          id: '1',
          name: 'Food Bank LA',
          location: [34.0522, -118.2437],
          type: 'food',
          address: '123 Main St, Los Angeles, CA',
          contact: '(213) 555-0123'
        },
        {
          id: '2',
          name: 'Medical Supplies Center',
          location: [34.0622, -118.2537],
          type: 'medical',
          address: '456 Spring St, Los Angeles, CA',
          contact: '(213) 555-0456'
        },
        {
          id: '3',
          name: 'Emergency Shelter',
          location: [34.0422, -118.2337],
          type: 'shelter',
          address: '789 Hope St, Los Angeles, CA',
          contact: '(213) 555-0789'
        }
      ];
    }
  }
}; 