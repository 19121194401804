import React, { useState, useEffect } from 'react';
import { Box, Typography, List, ListItem, ListItemText, Stack, Chip, CircularProgress, Alert, Button } from '@mui/material';
import { Resource, api } from '../services/api';

interface LeftPanelProps {
  onResourceSelect?: (resource: Resource) => void;
  selectedResource?: Resource | null;
}

const LeftPanel: React.FC<LeftPanelProps> = ({
  onResourceSelect,
  selectedResource,
}) => {
  const [resources, setResources] = useState<Resource[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchResources = async () => {
      try {
        setLoading(true);
        setError(null);
        const data = await api.getResources();
        setResources(data);
      } catch (err) {
        console.error('Error fetching resources:', err);
        setError(err instanceof Error ? err.message : 'Failed to load resources');
      } finally {
        setLoading(false);
      }
    };

    fetchResources();
  }, []);

  const handleResourceClick = (resource: Resource) => {
    if (onResourceSelect) {
      onResourceSelect(resource);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <Stack spacing={2} alignItems="center">
          <CircularProgress sx={{ color: '#000000' }} />
          <Typography variant="body2">Loading resources...</Typography>
        </Stack>
      </Box>
    );
  }

  const header = (
    <>
      <Typography variant="h6" gutterBottom sx={{ letterSpacing: '0.05em', mb: 4 }}>
        Available Resources
      </Typography>

      <Typography variant="subtitle2" sx={{ mb: 2, letterSpacing: '0.05em' }}>
        LOCATIONS ({resources.length})
      </Typography>
    </>
  );

  if (error) {
    return (
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        {header}
        <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
          <Alert 
            severity="error" 
            sx={{ 
              mb: 2,
              '& .MuiAlert-icon': { color: '#000000' },
              '& .MuiAlert-message': { color: '#000000' }
            }}
          >
            {error}
          </Alert>
          <Button 
            variant="contained" 
            fullWidth
            onClick={() => window.location.reload()}
          >
            Retry Loading
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box 
      sx={{ 
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {header}
      
      <Box 
        sx={{ 
          flexGrow: 1,
          overflow: 'auto',
          minHeight: 0,
        }}
      >
        {resources.length === 0 ? (
          <Alert 
            severity="info"
            sx={{ 
              mb: 2,
              '& .MuiAlert-icon': { color: '#000000' },
              '& .MuiAlert-message': { color: '#000000' }
            }}
          >
            No resources found in the database.
          </Alert>
        ) : (
          <List>
            {resources.map((resource) => (
              <ListItem 
                key={resource.id}
                sx={{ 
                  px: 0,
                  borderBottom: '1px solid #000000',
                  '&:last-child': { borderBottom: 'none' },
                  cursor: 'pointer',
                  bgcolor: selectedResource?.id === resource.id ? 'rgba(0, 0, 0, 0.05)' : 'transparent',
                  '&:hover': {
                    bgcolor: 'rgba(0, 0, 0, 0.05)',
                  }
                }}
                onClick={() => handleResourceClick(resource)}
              >
                <ListItemText
                  primary={resource.name}
                  secondary={
                    <Stack direction="row" spacing={1} mt={0.5}>
                      <Chip 
                        label={resource.type.toUpperCase()} 
                        size="small"
                        sx={{ 
                          bgcolor: '#000000',
                          color: '#FFFFFF',
                          borderRadius: 0,
                          height: 20,
                          fontSize: '0.7rem',
                        }}
                      />
                      {resource.address && (
                        <Typography variant="caption" color="text.secondary" noWrap>
                          {resource.address}
                        </Typography>
                      )}
                    </Stack>
                  }
                />
              </ListItem>
            ))}
          </List>
        )}
      </Box>
    </Box>
  );
};

export default LeftPanel; 