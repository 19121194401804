import React, { useState } from 'react';
import { Box, CssBaseline, ThemeProvider, createTheme, Grid, Paper } from '@mui/material';
import Map from './components/Map';
import LeftPanel from './components/LeftPanel';
import RightPanel from './components/RightPanel';
import { Resource } from './services/api';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#000000',
    },
    background: {
      default: '#FFFFFF',
      paper: '#FFFFFF',
    },
    text: {
      primary: '#000000',
      secondary: '#000000',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          textTransform: 'none',
          padding: '12px 24px',
        },
        contained: {
          backgroundColor: '#000000',
          color: '#FFFFFF',
          '&:hover': {
            backgroundColor: '#000000',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: 0,
            borderColor: '#000000',
            '&:hover': {
              borderColor: '#000000',
            },
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#000000',
        },
      },
    },
  },
});

const App: React.FC = () => {
  const [selectedResource, setSelectedResource] = useState<Resource | null>(null);

  const handleResourceSelect = (resource: Resource | null) => {
    console.log('App: Resource selected:', resource);
    setSelectedResource(resource);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ height: '100vh', width: '100vw', overflow: 'hidden', bgcolor: '#FFFFFF' }}>
        <Grid container sx={{ height: '100%' }}>
          <Grid item xs={3} sx={{ height: '100%' }}>
            <Paper 
              sx={{ 
                height: '100%',
                overflow: 'hidden',
                borderRadius: 0,
                borderRight: '1px solid #000000',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box sx={{ p: 3, height: '100%', overflow: 'hidden' }}>
                <LeftPanel 
                  selectedResource={selectedResource}
                  onResourceSelect={handleResourceSelect}
                />
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={6}>
            <Box sx={{ height: '100%', bgcolor: '#FFFFFF' }}>
              <Map 
                selectedResource={selectedResource}
                onResourceSelect={handleResourceSelect}
              />
            </Box>
          </Grid>

          <Grid item xs={3}>
            <Paper 
              sx={{ 
                height: '100%', 
                p: 3, 
                borderRadius: 0,
                overflow: 'auto',
                borderLeft: '1px solid #000000',
              }}
            >
              <RightPanel 
                selectedResource={selectedResource}
              />
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default App; 