import React from 'react';
import { Box, Typography, Paper, Divider, Stack } from '@mui/material';
import { Resource } from '../services/api';

interface RightPanelProps {
  selectedResource: Resource | null;
}

interface MetadataGroupProps {
  title: string;
  children: React.ReactNode;
}

const MetadataGroup: React.FC<MetadataGroupProps> = ({ title, children }) => (
  <Box sx={{ mb: 3 }}>
    <Typography variant="subtitle2" sx={{ letterSpacing: '0.05em', mb: 1 }}>
      {title.toUpperCase()}
    </Typography>
    <Stack spacing={1}>
      {children}
    </Stack>
  </Box>
);

interface MetadataItemProps {
  label: string;
  value: any;
}

const MetadataItem: React.FC<MetadataItemProps> = ({ label, value }) => {
  if (value === undefined || value === null || value === '') return null;
  
  const renderValue = (val: any): React.ReactNode => {
    if (Array.isArray(val)) {
      return (
        <Box sx={{ pl: 2 }}>
          {val.map((item, index) => (
            <Box key={index}>
              {typeof item === 'object' ? (
                Object.entries(item).map(([key, value]) => (
                  <MetadataItem key={key} label={key} value={value} />
                ))
              ) : (
                <Typography variant="body2">{item}</Typography>
              )}
            </Box>
          ))}
        </Box>
      );
    }
    
    if (typeof val === 'object' && val !== null) {
      return (
        <Box sx={{ pl: 2 }}>
          {Object.entries(val).map(([key, value]) => (
            <MetadataItem 
              key={key} 
              label={key.replace(/_/g, ' ').toUpperCase()} 
              value={value} 
            />
          ))}
        </Box>
      );
    }
    
    return (
      <Typography variant="body2" sx={{ 
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word'
      }}>
        {String(val)}
      </Typography>
    );
  };
  
  return (
    <Box>
      <Typography variant="caption" color="text.secondary" display="block">
        {label.replace(/_/g, ' ').toUpperCase()}
      </Typography>
      {renderValue(value)}
    </Box>
  );
};

const RightPanel: React.FC<RightPanelProps> = ({ selectedResource }) => {
  // Extract all metadata from the resource
  const metadata = selectedResource ? { ...selectedResource } : null;
  const { id, name, type, location, address, contact, ...additionalMetadata } = metadata || {};

  // Group additional metadata by category
  const groupedMetadata: { [key: string]: any } = {};
  if (additionalMetadata) {
    Object.entries(additionalMetadata).forEach(([key, value]) => {
      const category = key.includes('_') ? key.split('_')[0].toUpperCase() : 'OTHER';
      if (!groupedMetadata[category]) {
        groupedMetadata[category] = {};
      }
      groupedMetadata[category][key] = value;
    });
  }

  return (
    <Box 
      sx={{ 
        height: '100vh',
        maxHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      {/* Fixed Header */}
      <Typography variant="h6" sx={{ letterSpacing: '0.05em', mb: 3, flexShrink: 0 }}>
        Resource Details
      </Typography>

      {/* Scrollable Content */}
      <Box sx={{ 
        flexGrow: 1, 
        overflow: 'auto', 
        minHeight: 0,
        maxHeight: 'calc(100vh - 120px)',
        '&::-webkit-scrollbar': {
          width: '8px',
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#888',
          borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555',
        },
      }}>
        <Paper 
          elevation={0} 
          sx={{ 
            p: 2, 
            border: '1px solid #000000',
            borderRadius: 0,
            height: 'auto',
          }}
        >
          {!selectedResource ? (
            <Typography variant="body2" color="text.secondary">
              Select a resource on the map or from the list to see details
            </Typography>
          ) : (
            <Box>
              {/* Basic Information */}
              <MetadataGroup title="Basic Information">
                <Typography variant="h6" gutterBottom>
                  {name}
                </Typography>
                <MetadataItem label="Type" value={type} />
                <MetadataItem label="ID" value={id} />
              </MetadataGroup>

              <Divider sx={{ my: 2 }} />

              {/* Contact Information */}
              <MetadataGroup title="Contact Information">
                <MetadataItem label="Address" value={address} />
                <MetadataItem label="Contact" value={contact} />
              </MetadataGroup>

              <Divider sx={{ my: 2 }} />

              {/* Location Information */}
              <MetadataGroup title="Location Information">
                <MetadataItem label="Latitude" value={location?.[0]} />
                <MetadataItem label="Longitude" value={location?.[1]} />
              </MetadataGroup>

              {/* Additional Metadata Groups */}
              {Object.entries(groupedMetadata).map(([category, fields]) => (
                <React.Fragment key={category}>
                  <Divider sx={{ my: 2 }} />
                  <MetadataGroup title={category}>
                    {Object.entries(fields).map(([key, value]) => (
                      <MetadataItem 
                        key={key} 
                        label={key} 
                        value={value}
                      />
                    ))}
                  </MetadataGroup>
                </React.Fragment>
              ))}
            </Box>
          )}
        </Paper>
      </Box>
    </Box>
  );
};

export default RightPanel; 